import React, { useContext } from "react";
import useVisible from "../../hooks/useVisible.jsx";
import CurrentUserContext from "../../contexts/CurrentUserContext";

function ImportElementsPremiere({
  selectedTag,
  buttonTag,
  generateButtonTags,
}) {
  const [isVisible, setIsVisible] = useVisible(
    "ImportElementsPremiereVisibility"
  );

  const [setMatte, setSetMatte] = useVisible("setMatte");
  const [trackMatte, setTrackMatte] = useVisible("trackMatte");

  const currentUser = useContext(CurrentUserContext);

  const handleClick = (script, argument) => {
    window.jsx.evalFile("./Harchenko/AE2 Extension/ppData.jsx");
    window.jsx.evalScript(`run(${script}(${argument}));`, function (result) {});
  };

  if (selectedTag && selectedTag !== buttonTag) {
    return null;
  }

  return (
    <>
      <li
        onClick={() => setIsVisible(!isVisible)}
        className={`button-card resolution-toggle ${isVisible ? "active" : ""}`}
      >
        <div className="button-card__icon">
          <i class="fa-regular fa-square-e"></i>
          <div />
        </div>
        <div className="button-card__title">Import Elements</div>
        {generateButtonTags()}
      </li>
      <div
        className={`render-group ${isVisible ? "" : "render-group--hidden"}`}
      >
        <div className="render-group__checkbox-group">
          <div className="render-group__checkbox">
            <input
              type="checkbox"
              id="trackMatte"
              checked={trackMatte}
              onChange={() => {
                setTrackMatte(!trackMatte);
                // setSetMatte(false);
              }}
              className="hidden-checkbox"
            />
            <label htmlFor="trackMatte">
              <span className="custom-checkbox"></span>
              <span className="label-text">Track Matte</span>
            </label>
          </div>
          <div className="render-group__checkbox">
            <input
              type="checkbox"
              id="setMatte"
              checked={setMatte}
              onChange={() => {
                // setTrackMatte(false);
                setSetMatte(!setMatte);
              }}
              className="hidden-checkbox"
            />
            <label htmlFor="setMatte">
              <span className="custom-checkbox"></span>
              <span className="label-text">Set Matte</span>
            </label>
          </div>
        </div>

        <div
          onClick={() =>
            handleClick(
              "importAEData",
              JSON.stringify({
                name: currentUser.login,
                trackMatte: trackMatte,
                setMatte: setMatte,
              })
            )
          }
          className="resolution-tools__button main__button"
        >
          <i class="fa-regular fa-arrow-up-to-arc fa-rotate-90"></i>
          <span>Import Elements</span>
        </div>

        {/* <div className="videobolt-tools">
          <div
            onClick={() => handleClick("runEval", "'Import Sort Files'")}
            className="resolution-tools__button"
          >
            <i class="fa-solid fa-bars-sort"></i>
            <span>Sort Files</span>
          </div>
          <div
            onClick={() => handleClick("importText", true)}
            className="resolution-tools__button"
          >
            <i class="fa-solid fa-text"></i>
            <span>Text Layer</span>
          </div>
          <div
            onClick={() => handleClick("importTrackMatte", true)}
            className="resolution-tools__button"
          >
            <i class="fa-sharp fa-regular fa-object-group"></i>
            <span>Track Matte</span>
          </div>
          <div
            onClick={() => handleClick("importSetMatte", true)}
            className="resolution-tools__button"
          >
            <i class="fa-sharp fa-regular fa-object-group"></i>
            <span>Set Matte</span>
          </div>
          <div
            onClick={() => handleClick("runEval", "'Import Colors'")}
            className="resolution-tools__button"
          >
            <i class="fa-regular fa-palette"></i>
            <span>Colors Effect</span>
          </div>
          <div
            onClick={() => handleClick("runEval", "'Import Shift Channels")}
            className="resolution-tools__button"
          >
            <i class="fa-regular fa-sliders"></i>
            <span>Shift Channels</span>
          </div>
          <div
            onClick={() => handleClick("importLockLayer", true)}
            className="resolution-tools__button"
          >
            <i class="fa-sharp fa-regular fa-lock"></i>
            <span>Lock Layer</span>
          </div>
          <div
            onClick={() => handleClick("originalName", true)}
            className="resolution-tools__button"
          >
            <i class="fa-light fa-input-text"></i>
            <span>Original Name</span>
          </div>
        </div> */}
      </div>
    </>
  );
}

export default ImportElementsPremiere;
